.app {
  padding: 100px 20px;
  background: #161719;

  @media screen and (min-width: 1200px) {
    padding: 100px 300px;
  }
}

header {
  min-height: calc(100vh - 100px);
}

h1 {
  color: white;
  font-size: 46px;

  @media screen and (min-width: 1200px) {
    font-size: 58px;
    max-width: 840px;
  }
}

h2 {
  color: white;
  font-size: 18px;
  line-height: 34px;
  margin: 20px 0;

  @media screen and (min-width: 1200px) {
    font-size: 34px;
    padding-right: 200px;
    line-height: 48px;
  }
}

h3 {
  color: white;
  font-size: 26px;
  line-height: 40px;
  margin-bottom: 50px;

  @media screen and (min-width: 1200px) {
    font-size: 30px;
    line-height: 40px;
  }
}

p {
  color: white;
  font-size: 18px;
  margin-bottom: 40px;
  text-align: justify;

  @media screen and (min-width: 1200px) {
    font-size: 22px;
  }
}

section {
  padding: 100px 0;
}

.image-container {
  width: 150px;
  height: 150px;

  @media screen and (min-width: 1200px) {
    width: 200px;
    height: 200px;
  }
}

.profile-pic {
  width: 100%;
  height: auto;
  object-fit: cover;
}

footer {
  color: white;
  padding: 50px 0 0;
}

.App-link {
  color: orange;
  text-decoration: none;
}

.in-touch {
  font-size: 32px;
  margin-bottom: 24px;
}

.links-container {
  font-size: 24px;
}

.copyright {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 100px;
}
